@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";

.Grid {
	@include grid;

	&___right {
		@include grid--right;
	}

	&___left {
		@include grid--left;
	}

	&___wrap {
		@include grid--wrap;
	}

	&___top {
		@include grid--top;
	}

	&___center {
		@include grid--center;
	}

	&___horizontalCenter {
		@include grid--horizontal-center;
	}

	&___bottom {
		@include grid--bottom;
	}

	&___full {
		@include grid--full;

		> * {
			@include grid__cell--full;
		}
	}

	&___noGutter {
		> * {
			@include grid__cell--no-gutter;
		}
	}

	&___largeGutter {

		> * {
			@include grid__cell--large-gutter;
		}
	}

	&___rowGap {
		> * {
			@include grid__cell--row-gap;
		}
	}

	&___spaceBetween {
		justify-content: space-between;
		> * {
			flex-basis: auto;
			flex-grow: 0;
		}
	}

	&___stretch {
		align-items: stretch;
	}

	&___container {
		margin-left: calc(-1 * var(--grid-gutter));
		margin-right: calc(-1 * var(--grid-gutter));

		&.Grid___largeGutter {
			@include mq("lg") {
				margin-left: calc(-1 * var(--grid-gutter--lg));
				margin-right: calc(-1 * var(--grid-gutter--lg));
			}
		
		}
	}
}
