@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.list-mixins.scss";

.LanguageSelector {
	margin-left: var(--spacing--sm);
	cursor: pointer;
}

.LanguageSelector_list {
	position: relative;
	background-color: var(--color-secondary);

	display: none;
	@include mq("lg") {
		display: block;
	}
}

.LanguageSelector_select {
	height: 30px;
	color: var(--color-light);
	background-color: var(--color-secondary);
	border: none;
	padding: 0 var(--spacing--xs) 0 var(--spacing--sm);

	display: block;
	@include mq("lg") {
		display: none;
	}
}

.LanguageSelector_toggle {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	gap: var(--spacing--sm);
	height: 30px;				
	padding: 0 var(--spacing--xs) 0 var(--spacing--sm);
	color: var(--color-light);
}

.LanguageSelector_dropdown {
	@include reset-list;
	background-color: var(--color-secondary);
	position: absolute;
	visibility: hidden;
	width: 100%;

	&.isOpen {
		visibility: visible;
		z-index: 1;
	}
}

.LanguageSelector_link {
	text-decoration: none;
}

.LanguageSelector_item {
	padding: 0 var(--spacing--sm);
	white-space: nowrap;
	height: 30px;
	display: flex;
	align-items: center;
	color: var(--color-light);
	font-weight: var(--font-weight--normal);
}

.LanguageSelector_icon svg {
	transform: rotate(-270deg);

	@include transition {
		transition: transform var(--trans-time--long);
	}
	.isOpen & {
		transform: rotate(-90deg);
	}
	path {
		stroke: var(--color-white);
	}
}
