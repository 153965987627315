@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.Header {
	position: fixed;
	top: 0;
	width: 100%;
	font-size: var(--font-size--sm);
	color: var(--color-black);
	padding: var(--spacing--base) 0;
	z-index: var(--z-index--header);
	will-change: transform, background-color, opacity, margin;
	background: var(--color-white);
	transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);

	@include mq("md") {
		padding: var(--spacing--sm) 0;
	}

	&___transparent {
		background-color: transparent;
	}

	&___hidden {
		transform: translateY(-101%);
	}

	&___toggled {
		animation: fadeInDown 500ms ease-in-out;
		background-color: var(--color-grey-lightest);
		top: 0;
		height: 100vh;
		overflow: auto;
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.Header_identity {
	// Font size controls the icons size
	color: var(--color-white);
	font-size: 2rem;
	@include mq("md", max) {
		flex: 1;
	}
}

.Header_navigation {
	text-align: right;
	flex: 1;
	opacity: 0;
	@include transition {
		transition: opacity var(--trans-time--longer);
	}
	@include mq("md", max) {
		order: 2;
		flex: initial;
	}
	[aria-expanded="true"] & {
		opacity: 1;
	}
}

.Header_nav {
	width: 100%;
	height: calc(100vh - #{100px});
	top: 93px;
	left: 0;
	margin: auto;
	padding: 2em;

	@include mq("md") {
		padding: 0;
	}

	@include mq("md") {
		background-color: transparent;
		height: auto;
		display: block;
		position: static;
	}
}

.Header_close {
	text-align: end;
}

.Header_navigation {
	padding-left: 5vw;

	@include mq("md") {
		padding-left: 16vw;
	}

	@include mq("md") {
		padding-left: 24vw;
	}

	@include mq("xl") {
		padding-left: 33vw;
	}
}

.Header_navToggle {
	@include paragraph;
	cursor: pointer;
	color: var(--color-black);
	@include transition {
		transition: fill 300ms ease-in-out;
	}
}

.Header_navController {
	display: none;

	&:checked ~ .Header_nav {
		display: block;
	}

	&:checked ~ .Header_navToggle {
		text-decoration: underline;
		color: var(--color-black);
	}
}

.Header_navToggle___light {
	color: var(--color-white);
}

.Header___light {
	.Header_navToggle {
		color: var(--color-white);
	}

	.Header_identity {
		svg {
			fill: var(--color-white);
		}
	}
}

.Header___toggled.Header___light {
	svg {
		fill: var(--color-black);
	}

	.Header_navToggle {
		color: var(--color-black);
		text-decoration: underline;
	}
}

// Theme: Kogp
[data-theme="kogp"] {
	.Header___light {
		svg {
			color: var(--color-white);
		}
	}
}
