@import "ui/styles/2-tools/tools.display-mixins";
@import "ui/styles/2-tools/tools.behavior.scss";

.Logo {
	text-decoration: none;
	color: currentColor;
}

.Logo_heading {
	@include hidden-absolute;
}

.Logo_asset {
	display: block;
	width: 7rem;
	height: 2rem;
}

.Logo_asset svg{
	fill: var(--color-black);
	@include transition {
		transition: fill 300ms ease-in-out;
	}
}

:global(.Header___light) {
	.Logo_asset svg{
		fill: var(--color-white);		
	}
}

// Theme: Kogp
[data-theme='kogp'] {
	.Logo_asset {
		width: 12em;
		height: 3em;
		color: var(--color-primary);
	}
}