@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.ServiceNavigation {
	@include reset-list;
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-bottom: 20rem;
}

.ServiceNavigation_item {
	@include mq("md") {
		margin-bottom: 0;
	}
}

.ServiceNavigation_link {
	width: fit-content;
	display: block;
	font-size: var(--font-size--md);
	line-height: var(--line-height--md);
	color: var(--color-primary-gray-50);
	margin-bottom: 0.8rem;

	@include mq("md") {
		font-size: var(--font-size--xl);
		margin-bottom: 1.6rem;
	}
}
