@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Heading {
	&___xs {
		@include heading--xl;
	}

	&___sm {
		@include heading--xl;
	}

	&___reg {
		@include heading--2xl;
	}

	&___md {
		@include heading--3xl;
	}

	&___lg {
		@include heading--4xl;
	}

	&___xl {
		@include heading--5xl;
	}

	&___xxl {
		@include heading--6xl;
	}
}
