@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Navigation {
	margin: 5rem auto 2.5rem;

	&___primary {
		@include mq("md") {
			margin: 18rem auto 3.7rem;
		}
	}
}

.Navigation_heading {
	@include hidden-absolute;
}

.Navigation_list {
	@include reset-list;
	list-style: none;
	flex-direction: column;
	text-align: left;
	display: flex;

	@include mq("md") {
		flex-direction: row;
	}

	.Navigation___primary & {
		@include mq("md") {
			flex-direction: column;
		}
	}
}

.Navigation_item {
	text-align: left;
}

.Navigation_link {
	color: currentColor;
	white-space: nowrap;
	display: inline-block;
	padding: 0;
	margin: 0;
	cursor: pointer;
	margin-bottom: 0.8rem;

	@include mq("sm", max) {
		@include heading--2xl;
	}

 
}
