@import "ui/styles/2-tools/tools.media-query.scss";

.Container {
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--base);

	@include mq("md") {
		padding: 0 var(--spacing--2xl);
	}

	&___standard {
		max-width: 172.8rem;
	}

	&___narrow {
		max-width: 121.6rem;
	}

	&___slim {
		max-width: 72rem;
	}

	&___skinny {
		max-width: 66rem;
	}

	&___fillWidth {
		width: 100%;
	}
}
