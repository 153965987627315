@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Paragraph {
	@include paragraph;

	&___right {
		text-align: right;
	}

	&___bold {
		@include paragraph-md;
	}
}


// Theme: Kogp
[data-theme='kogp'] {
	.Paragraph {
		&___bold {
			font-weight: var(--font-weight--extrablack);
		}
	}
}